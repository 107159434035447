import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: null,
};

export const studentReducer = createSlice({
  name: "student",
  initialState,
  reducers: {
    createStudentRequest: (state) => {
      state.loading = true;
    },
    createStudentSuccess: (state) => {
      state.loading = false;
    },
    createStudentError: (state) => {
      state.loading = false;
    },
  },
});

export const {
  createStudentRequest,
  createStudentSuccess,
  createStudentError,
} = studentReducer.actions;

export default studentReducer.reducer;

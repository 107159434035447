import React from "react";

import { LoadingOutlined } from "@ant-design/icons";

import LoadingWrapper from "./style";

const styleIcon = { fontSize: 16, color: "#1677ff" };

const Loading = () => (
  <LoadingWrapper>
    <LoadingOutlined style={styleIcon} />
  </LoadingWrapper>
);

export default Loading;

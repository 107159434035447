import styled from "styled-components";

const GlobalStyleWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 15px;
  }
  .ant-form-item-label {
    padding-bottom: 0;
  }

  a {
    :hover {
      opacity: 0.6;
    }
  }
`;

export default GlobalStyleWrapper;

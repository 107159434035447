import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: null,
};

export const habilitysReducer = createSlice({
  name: "habilitys",
  initialState,
  reducers: {
    habilityRequest: (state) => {
      state.loading = true;
    },
    habilitySuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    habilityError: (state) => {
      state.loading = false;
    },
  },
});

export const { habilityRequest, habilitySuccess, habilityError } =
  habilitysReducer.actions;

export default habilitysReducer.reducer;

const languages = {
  ptBr: {
    login: {
      signin: {
        title: "Entrar",
        message: "Digite seus dados abaixo para acessar a plataforma",
        inputEmail: "Seu e-mail",
        inputPassword: "Sua senha",
        button: "Entrar",
      },
    },
  },
  enUs: {
    login: {
      signin: {
        title: "Sig-in",
        message: "Enter your details below to access the platform",
        inputEmail: "Your email",
        inputPassword: "Your password",
        button: "Sig-in",
      },
    },
  },
};

export default languages;

function setStorage(item, value) {
  return localStorage.setItem(`maketplace_${item}`, JSON.stringify(value));
}

function getStorage(item) {
  const itemStorage = localStorage.getItem(`maketplace_${item}`);

  return JSON.parse(itemStorage);
}

function removeStorage(item) {
  localStorage.removeItem(`maketplace_${item}`);
}

export { setStorage, getStorage, removeStorage };

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  profile: {},
  socials: {},
  habilitys: {},
};

export const profileReducer = createSlice({
  name: "profile",
  initialState,
  reducers: {
    profileRequest: (state) => {
      state.loading = true;
    },
    profileSuccess: (state, action) => {
      state.loading = false;
      state.profile = action.payload.data;
    },
    profileError: (state) => {
      state.loading = false;
    },
    socialsRequest: (state) => {
      state.loading = true;
    },
    socialsSuccess: (state, action) => {
      state.loading = false;
      state.socials = action.payload.data;
    },
    socialsError: (state) => {
      state.loading = false;
    },
    habilityStudentSuccess: (state, action) => {
      state.habilitys = action.payload.data;
    },
  },
});

export const {
  profileRequest,
  profileSuccess,
  profileError,
  socialsRequest,
  socialsSuccess,
  socialsError,
  habilityStudentSuccess,
} = profileReducer.actions;

export default profileReducer.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: {},
  typeListProfessional: "mini",
};

export const professionalsReducer = createSlice({
  name: "professionals",
  initialState,
  reducers: {
    professionalsRequest: (state) => {
      state.loading = true;
    },
    professionalsSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data.professionals;
      state.typeListProfessional = action.payload.data.typeListProfessional;
    },
    professionalsError: (state) => {
      state.loading = false;
    },
  },
});

export const {
  professionalsRequest,
  professionalsSuccess,
  professionalsError,
} = professionalsReducer.actions;

export default professionalsReducer.reducer;

import { configureStore } from "@reduxjs/toolkit";

import authReducer from "../reducers/accountReducer";
import avaliationReducer from "../reducers/avaliationReducer";
import profileReducer from "../reducers/profileReducer";
import studentReducer from "../reducers/studentReducer";
import habilitysReducer from "../reducers/habilitysReducer";
import portfolioReducer from "../reducers/portfolioReducer";
import professionalsReducer from "../reducers/professionalsReducer";

export default configureStore({
  reducer: {
    accountconfig: authReducer,
    avaliation: avaliationReducer,
    profile: profileReducer,
    student: studentReducer,
    habilitys: habilitysReducer,
    portfolio: portfolioReducer,
    professionals: professionalsReducer,
  },
});

import React from "react";
import ReactDOM from "react-dom/client";

import "moment/locale/pt-br";

import moment from "moment";

import { Provider } from "react-redux";

import { RouterProvider } from "react-router-dom";

import { ConfigProvider } from "antd";
import BR from "antd/locale/pt_BR";

import router from "./routers";
import store from "./store";

import GlobalStyle from "./GlobalStyle";
import ContextGlobalProvider from "./GlobalContext";

moment.locale("pt-br");

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <ContextGlobalProvider>
        <GlobalStyle>
          <ConfigProvider locale={BR}>
            <RouterProvider router={router} />
          </ConfigProvider>
        </GlobalStyle>
      </ContextGlobalProvider>
    </Provider>
  </React.StrictMode>
);

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  avaliation: [],
};

export const avaliationReducer = createSlice({
  name: "avaliation",
  initialState,
  reducers: {
    avaliationRequest: (state) => {
      state.loading = true;
    },
    avaliationSuccess: (state, action) => {
      state.loading = false;
      state.avaliation = action.payload.data;
    },
    avaliationError: (state) => {
      state.loading = false;
    },
  },
});

export const { avaliationRequest, avaliationSuccess, avaliationError } =
  avaliationReducer.actions;

export default avaliationReducer.reducer;
